import Splide from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

document.addEventListener("DOMContentLoaded", function () {
  if (
    document.getElementsByClassName("hd-content__content-slider").length >= 1
  ) {
    var splide = new Splide(".hd-content__content-slider", {
      type: "loop",
      autoplay: true,
      pagination: false,
      arrows: false,
      perPage: 1,
      perMove: 1,
      pauseOnFocus: false,
      pauseOnHover: false,
      gap: "42px",
    });

    splide.mount();
  }
  if (
    document.getElementsByClassName("hd-content__image-grid--mobile").length >=
    1
  ) {
    var splide = new Splide(".hd-content__image-grid--mobile", {
      type: "loop",
      pagination: false,
      arrows: false,
      perPage: 2,
      pauseOnFocus: false,
      pauseOnHover: false,
      gap: "16px",
      autoScroll: {
        speed: 0.5,
      },
    });
    splide.mount({ AutoScroll });
  }

  if (document.getElementsByClassName("stories-carousel").length >= 1) {
    var splide = new Splide(".stories-carousel", {
      type: "slide",
      pagination: false,
      arrows: true,
      perMove: 1,
      gap: "48px",
      breakpoints: {
        768: {
          gap: "24px",
        },
        992: {
          gap: "24px",
        },
      },
    });
    splide.mount();

    // Function to re-initialize the Splide slider
    function reinitializeSplide() {
      splide = new Splide(".stories-carousel", {
        type: "slide",
        pagination: false,
        arrows: true,
        perMove: 1,
        gap: "48px",
        breakpoints: {
          768: {
            gap: "24px",
          },
          992: {
            gap: "24px",
          },
        },
      });
      splide.mount();
    }

    // Set up a MutationObserver to watch for changes in the li items
    const targetNode = document.querySelector(".stories-carousel ul");
    const config = { childList: true, subtree: true };

    const callback = function (mutationsList, observer) {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList") {
          reinitializeSplide();
          break;
        }
      }
    };

    const observer = new MutationObserver(callback);
    if (targetNode) {
      observer.observe(targetNode, config);
    }
  }
});
