document.addEventListener("DOMContentLoaded", function () {
  function checkScroll() {
    const controls = document.querySelector(".site-header__nav--desktop");
    const titleHome = document.querySelector(".site-header__main--title");
    const titleSingle = document.querySelector(
      ".site-header__is-single--title"
    );
    const singlePostTitle = document.querySelector(".single-post__title");
    const mobile = document.querySelector(".site-header__nav--mobile");

    if (!controls && !mobile) return;

    let shouldAddClass = false;

    if (titleHome) {
      const titleHomePosition = titleHome.getBoundingClientRect().top;
      if (titleHomePosition <= 152) {
        shouldAddClass = true;
      }
    }

    if (titleSingle) {
      const titleSinglePosition = titleSingle.getBoundingClientRect().top;
      if (titleSinglePosition <= 152) {
        shouldAddClass = true;
      }
    }

    if (singlePostTitle) {
      const singlePostTitlePosition =
        singlePostTitle.getBoundingClientRect().top;
      if (singlePostTitlePosition <= 152) {
        shouldAddClass = true;
      }
    }

    if (shouldAddClass) {
      if (controls) {
        controls.classList.add("scrollState");
      }
      if (mobile) {
        mobile.classList.add("scrollState");
      }
    } else {
      if (controls) {
        controls.classList.remove("scrollState");
      }
      if (mobile) {
        mobile.classList.remove("scrollState");
      }
    }
  }

  window.addEventListener("scroll", checkScroll);
  checkScroll(); // Check scroll position on page load
});
