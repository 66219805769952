/* -------------------------------------------------------------------------- */
/*                        Adding overlay and show modal                       */
/* -------------------------------------------------------------------------- */
document.addEventListener("DOMContentLoaded", () => {
  const desktopLink = document.querySelector(
    '.main-menu__item--header-btn a[href="#free-demo"]'
  );
  const mobileLink = document.querySelector(
    '.mobile-menu__item--header-btn a[href="#free-demo"]'
  );
  var modal = document.getElementById("header-modal");
  const closeModal = document.getElementById("close-modal");
  const htmlElement = document.documentElement;

  if (desktopLink && mobileLink) {
    desktopLink.addEventListener("click", (event) => {
      event.preventDefault();

      if (modal.classList.contains("active")) {
        modal.classList.remove("active");
      } else {
        modal.classList.add("active");
      }

      if (htmlElement.style.overflow !== "hidden") {
        htmlElement.style.overflow = "hidden";
      } else {
        htmlElement.style.overflow = "";
      }
    });

    mobileLink.addEventListener("click", (event) => {
      event.preventDefault();

      if (modal.classList.contains("active")) {
        modal.classList.remove("active");
      } else {
        modal.classList.add("active");
      }

      if (htmlElement.style.overflow !== "hidden") {
        htmlElement.style.overflow = "hidden";
      } else {
        htmlElement.style.overflow = "";
      }
    });

    closeModal.addEventListener("click", (event) => {
      event.preventDefault();
      modal.classList.remove("active");
      htmlElement.style.overflow = "";
    });
  }
});

/* -------------------------------------------------------------------------- */
/*                     Button logic inside the modal above                    */
/* -------------------------------------------------------------------------- */

document.addEventListener("DOMContentLoaded", () => {
  var free_demo = document.getElementById("free-demo-form");
  var other_form = document.getElementById("other-form");

  var free_demo_content = document.getElementById("free-demo-form-content");
  var other_form_content = document.getElementById("other-form-content");

  free_demo_content.classList.add("visible");

  other_form.addEventListener("click", (event) => {
    event.preventDefault(); // Prevent the default anchor action
    // Toggle "active" class and "visible" class for the content
    if (!other_form_content.classList.contains("visible")) {
      other_form.classList.add("active");
      free_demo.classList.remove("active");
      other_form_content.classList.add("visible");
      free_demo_content.classList.remove("visible");
    }
  });

  free_demo.addEventListener("click", (event) => {
    event.preventDefault(); // Prevent the default anchor action
    // Toggle "active" class and "visible" class for the content
    if (!free_demo_content.classList.contains("visible")) {
      free_demo.classList.add("active");
      other_form.classList.remove("active");
      free_demo_content.classList.add("visible");
      other_form_content.classList.remove("visible");
    }
  });
});
