document.addEventListener("DOMContentLoaded", function () {
  document.querySelectorAll("#toggle-worker-info").forEach(function (toggle) {
    toggle.addEventListener("click", function (e) {
      e.preventDefault();
      var parentDiv = this.closest(".hd-content__team-grid--item");
      if (parentDiv) {
        parentDiv.classList.toggle("info-shown");
      }
    });
  });
});
