document.addEventListener("DOMContentLoaded", function () {
  var article = document.getElementById("articleContent");
  var contents = document.getElementById("articleContents");

  var headerTwo = document.querySelectorAll(".article__anchor");

  for (var i = 0; i < headerTwo.length; i++) {
    var clone = headerTwo[i];
    var contentItem = document.createElement("li");
    var contentItemLink = document.createElement("a");
    contentItemLink.href = "#" + headerTwo[i].getAttribute("id");
    contentItemLink.innerText = headerTwo[i].innerHTML;
    contentItem.appendChild(contentItemLink);
    contents?.appendChild(contentItem);
  }
});
