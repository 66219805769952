document.addEventListener("DOMContentLoaded", function () {
  jQuery(function ($) {
    // Cache jQuery selectors
    const $caseCategories = $("#post_categories");
    const $casesArticles = $("#posts-articles");
    const $archiveLoader = $(".archive-loader");

    // Function to handle category change
    function handleCategoryChange(selectedCategory) {
      // Find the selected option and get its name attribute as categoryName
      const categoryName = $caseCategories
        .find(`option[value='${selectedCategory}']`)
        .attr("name");

      // Update URL using categoryName instead of selectedCategory
      const newUrl = `?category=${encodeURIComponent(categoryName)}`;
      window.history.pushState({ path: newUrl }, "", newUrl);

      // Pass both selectedCategory and categoryName to loadContentForCategory
      loadContentForCategory(selectedCategory, categoryName);
    }

    // Function to load content for a given category and categoryName
    function loadContentForCategory(category, categoryName) {
      $.ajax({
        type: "POST",
        url: "/wp-admin/admin-ajax.php",
        dataType: "html",
        data: {
          action: "blog_filtering",
          category: category,
          categoryName: categoryName,
        },
        beforeSend: function () {
          $casesArticles.empty();
          $archiveLoader.addClass("loading");
        },
        success: function (res) {
          $archiveLoader.removeClass("loading");
          $casesArticles.html(res);
        },
      });
    }

    // Attach change event listener
    $caseCategories.on("change", function (e) {
      e.preventDefault();
      const selectedCategory = $caseCategories.val();
      handleCategoryChange(selectedCategory);
    });

    // Load content based on URL parameter on page load and popstate
    function loadContentBasedOnUrlParameter() {
      const categoryName = new URLSearchParams(window.location.search).get(
        "category"
      );
      if (categoryName) {
        // Find the option with the matching name attribute to get its value (category ID)
        const selectedCategory = $caseCategories
          .find(`option[name='${categoryName}']`)
          .val();
        if (selectedCategory) {
          $caseCategories.val(selectedCategory);
          loadContentForCategory(selectedCategory, categoryName);
        }
      }
    }

    // Call the function on page load to check URL parameters
    loadContentBasedOnUrlParameter();

    // Handle browser navigation events
    window.addEventListener("popstate", loadContentBasedOnUrlParameter);
  });
});
