document.addEventListener("DOMContentLoaded", function () {
  var item1 = document.getElementsByClassName("parallax-active");

  window.addEventListener("scroll", function () {
    if (window.innerWidth < 992) {
      return;
    } else {
      var scroll = window.scrollY / 5;
    }

    for (var i = 0; i < item1.length; i++) {
      var rect = item1[i].getBoundingClientRect();
      var offset = rect.top / window.innerHeight;
      item1[i].style.transform =
        "translate(46px, " + (-185 - offset * -100) + "px)";
    }
  });
});
